export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/attendance-policy/:pageId',
        component: () => import('@/views/page/company/hr-payroll/attendance-policy/List.vue'),
        name: 'attendance-policy',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/attendance-policy-add/:pageId',
        component: () => import('@/views/page/company/hr-payroll/attendance-policy/Add.vue'),
        name: 'attendance-policy-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/attendance-policy-allocation/:pageId',
        component: () => import('@/views/page/company/hr-payroll/attendance-policy-allocation/List.vue'),
        name: 'attendance-policy-allocation',
        meta: {
            requiresAuth: true
        }
    }
    ,
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/attendance-policy-allocation-add/:pageId',
        component: () => import('@/views/page/company/hr-payroll/attendance-policy-allocation/Add.vue'),
        name: 'attendance-policy-allocation-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/holidays/:pageId',
        component: () => import('@/views/page/company/hr-payroll/holidays/List.vue'),
        name: 'holidays',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/holidays-add/:pageId',
        component: () => import('@/views/page/company/hr-payroll/holidays/Add.vue'),
        name: 'holidays-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/leave-policy/:pageId',
        component: () => import('@/views/page/company/hr-payroll/leave-policy/List.vue'),
        name: 'leave-policy',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/leave-policy-add/:pageId',
        component: () => import('@/views/page/company/hr-payroll/leave-policy/Add.vue'),
        name: 'leave-policy-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/leave-balance/:pageId',
        component: () => import('@/views/page/company/hr-payroll/leave-balance/List.vue'),
        name: 'leave-balance',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/leave-balance-add/:pageId',
        component: () => import('@/views/page/company/hr-payroll/leave-balance/Add.vue'),
        name: 'leave-balance-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/my-attendance/:pageId',
        component: () => import('@/views/page/company/hr-payroll/my-attendance/List.vue'),
        name: 'my-attendance',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/my-leave/:pageId',
        component: () => import('@/views/page/company/hr-payroll/my-leave/List.vue'),
        name: 'my-leave',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/attendance-report/:pageId',
        component: () => import('@/views/page/company/hr-payroll/attendance-report/List.vue'),
        name: 'attendance-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/attendance-log-summary/:pageId',
        component: () => import('@/views/page/company/hr-payroll/attendance-report/AttendanceLog.vue'),
        name: 'attendance-log-summary',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/employee-register/:pageId',
        component: () => import('@/views/page/company/hr-payroll/employee-register/register/Index.vue'),
        name: 'employee-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-employee/:pageId',
        component: () => import('@/views/page/company/hr-payroll/employee-register/Add.vue'),
        name: 'add-employee',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/leave-application-approval/:pageId',
        component: () => import('@/views/page/company/hr-payroll/leave-application-approval/List.vue'),
        name: 'leave-application-approval',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/leave-application/:pageId',
        component: () => import('@/views/page/company/hr-payroll/leave-application/List.vue'),
        name: 'leave-application',
        meta: {
            requiresAuth: true
        }
    },
]